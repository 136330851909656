<template>
  <div>
    <b-modal
      v-model="deleteElement"
      :title="$t('Delete Check')"
      :ok-title="$t('Delete')"
      :cancel-title="$t('cancel')"
      ok-variant="danger"
      cancel-variant="primary"
      @close="deleteElement=false"
      @cancel="deleteElement=false"
      @ok="ConfirmDelete"
    >
      <b-badge
        class="mt-1"
        variant="light-danger"
      >
        {{ $t('are you sure you want to delete this Delivery') }}
      </b-badge>
    </b-modal>
    <h3 class="mb-3">
      {{ $t('Delivery') }}
    </h3>

    <b-card
      class="mt-5"
    >
      <b-card-title>
        <b-row>
          <b-col
            cols="1"
          />
          <b-col
            cols="4"
          >
            <h3>
              {{ $t('first_regestirtion') }}
            </h3>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$t('Type to Search')"
                @keyup.enter="onEnter"
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="2"
          />
          <b-col
            cols="4"
          >
            <h3>
              {{ $t('renew') }} / {{ $t('Sale') }}
            </h3>
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter1"
                type="search"
                :placeholder="$t('Type to Search')"
                @keyup.enter="onEnter1"
              />
            </b-input-group>
            <b-col
              cols="1"
            />
          </b-col></b-row>
      </b-card-title>
      <b-row
        v-if="chekker == false"
      >
        <b-col
          cols="4"
          md="4"
        />
        <b-col
          cols="4"
          md="4"
        >

          <b-button
            class="w-100 mb-1"
            variant="gradient-success"
            @click="create"
          >
            {{ $t('Complete') }}
          </b-button>
          <b-button
            class="w-100 mb-1"
            variant="gradient-success"
            @click="print"
          >
            {{ $t('Print') }}
          </b-button>
        </b-col>
        <b-col
          cols="4"
          md="4"
        />
        <b-card :title="$t('owwner applicant')">
          <b-row
            v-if="items"
          >
            <b-col
              v-for="el in ['id','owner', 'applicant','date_time', 'phone', 'identify']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="items[0]['owwner_data'][el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
        <b-card :title="$t('Checkk')">
          <b-row
            v-if="items"
          >
            <b-col
              v-for="el in ['no','No','chassis_number', 'chassis_number_type','owner_type1', 'created_year', 'first_registeraion',
                            'cabin_number','cabin_number_type','motor_number_type','motor_reserve_number','motor_reserve_number_type',
                            'motor_number','cabin_reserve_number_type','cabin_reserve_number','expiration_date','toll',
                            'color','fuel_type','car_type','motor_model','motor_manufacturer','wheel1','combustion1',
                            'light1','brake1','block_reason','block1','notes']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="items[0][el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <b-table
        v-if="items.length>0 && chekker == true"
        :fields="fields"
        responsive="sm"
        :items="items"
        class="table-w-max"
      >
        <template #cell(id)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            {{ data.item.no }}
          </b-badge>
        </template>
        <template
          #cell(created_at_updated_at)="data"
        >
          <div>
            <b-badge
              v-if="data.item.created_at"
              v-b-tooltip.hover="{title:data.item.created_at,variant:'success',placement:'top'}"
              variant="light-success"
            >
              <font-awesome-icon
                :icon="['fas', 'table']"
              />
              {{ data.item.created_from }}
            </b-badge>
            <b-badge
              v-if="data.item.updated_from"
              v-b-tooltip.hover="{ title:data.item.updated_from,variant:'warning',placement:'top'}"
              variant="light-warning"
              class="ml-1"
            >
              <font-awesome-icon
                :icon="['fas', 'calendar-week']"
              />
              {{ data.item.updated_from }}
            </b-badge>
          </div>
        </template>
        <template #cell(carr)="data">
          <b-badge
            pill
            :variant="data.item.carr_data?'light-primary':'light-danger'"
          >
            {{ data.item.carr_data?data.item.carr_data.manufacturer+' ( '+data.item.carr_data.model +' )':'ليست مرتبطة' }}
          </b-badge>
        </template>
        <template #cell(expiration_date)="data">
          <b-badge
            pill
            variant="light-primary"
          >
            <font-awesome-icon
              :icon="['fas', 'table']"
            />
            {{ data.value }}
          </b-badge>
        </template>

        <template #cell(owner_type)="data">
          <b-badge
            pill
            :variant="data.item.owner_type?'light-primary':'light-danger'"
          >
            {{ data.item.owner_type? 'خاصة':'عامة' }}
          </b-badge>
        </template>
        <template #cell(carr_data)="data">
          <b-badge
            pill
            :variant="data.item.carr_data?'light-primary':'light-danger'"
          >
            {{ data.item.carr_data?data.item.carr_data.manufacturer+' ( '+data.item.carr_data.model +' )':'ليست مرتبطة' }}
          </b-badge>
        </template>
        <template #cell(wheel)="data">
          <b-badge
            pill
            :variant="data.item.wheel?'light-primary':'light-danger'"
          >
            {{ data.item.wheel?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(light)="data">
          <b-badge
            pill
            :variant="data.item.light?'light-primary':'light-danger'"
          >
            {{ data.item.light?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(brake)="data">
          <b-badge
            pill
            :variant="data.item.brake?'light-primary':'light-danger'"
          >
            {{ data.item.brake?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(combustion)="data">
          <b-badge
            pill
            :variant="data.item.combustion?'light-primary':'light-danger'"
          >
            {{ data.item.combustion?'مقبول':'مرفوض' }}
          </b-badge>
        </template>
        <template #cell(first_check)="data">
          <b-badge
            pill
            :variant="data.item.first_check?'light-primary':'light-danger'"
          >
            {{ data.item.first_check?'مقبول':'مرفوض' }}
          </b-badge>
        </template>

        <template #cell(owwner_data)="data">
          <b-badge
            pill
            :variant="data.item.owwner_data?'light-primary':'light-danger'"
          >
            {{ data.item.owwner_data?data.item.owwner_data.owner:'ليست مرتبطة' }}
          </b-badge>
        </template>
        <template #cell(owwner_id)="data">
          <b-badge
            pill
            :variant="data.item.owwner_data?'light-primary':'light-danger'"
          >
            {{ data.item.owwner_data?data.item.owwner_data.id:'ليست مرتبطة' }}
          </b-badge>
        </template>

        <template #cell(fuel_type)="data">
          {{ $t(data.item.fuel_type) }}
        </template>
        <template #cell(car_type)="data">
          {{ $t(data.item.car_type) }}
        </template>
        <template #cell(platee)="data">
          <b-badge
            pill
            :variant="data.item.platee_data?'light-primary':'light-danger'"
          >
            {{ data.item.platee_data?data.item.platee_data.number:'ليست مرتبطة' }}
          </b-badge>
        </template>
      </b-table>
      <b-row
        v-else
        class="d-flex justify-content-center"
      >
        <b-col
          v-if="chekker == true"
          cols="4"
        >
          <b-alert
            variant="danger"
            show
          >
            <div class="alert-body text-center">
              <span>{{ $t('No Data Available') }}</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end mt-2"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            per-page="10"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card
      id="printMe"
      style="visibility: hidden;"
    >
      <img
        v-if="this.items[0].owner_type"
        src="@/assets/images/card/private.jpeg"
        alt=""
        :style="{'height':'100%','width':'100%' ,'position': 'absolute',
                 'top':'0','bottom':'0','left':'0','right':'0'}"
      >
      <img
        v-else
        src="@/assets/images/card/public.jpeg"
        alt=""
        :style="{'height':'100%','width':'100%' ,'position': 'absolute',
                 'top':'0','bottom':'0','left':'0','right':'0'}"
      >
      <h5
        style="position: fixed;
          top: -8px;
          font-family: Almarai;
          left: 2px;"
      >{{ this.items[0].No }}</h5>
      <h3
        style="position: fixed;
                            top: 23px;
                            font-family: Almarai;
                            left: 5px;"
      >{{ this.items[0].platee_data.number }}</h3>
      <h6
        style="position: fixed;
                            top: 60px;
                            font-family: Almarai;
                            left: 5px;"
      >{{ $t(this.items[0].platee_data.car_type) }} / {{ this.items[0].owner_type1 }}</h6>
      <h6
        v-if="this.items[0].owwner_data"
        style="position: fixed;
                            top: 89px;
                            font-family: Almarai;
                            left: 5px;"
      >{{ this.items[0].owwner_data.owner }}</h6>
      <h6
        v-if="this.items[0].owwner_data"
        style="position: fixed;
                            top:123px;
                            font-family: Almarai;
                            left: 5px;"
      >{{ this.items[0].owwner_data.identify }}</h6>
      <h3
        style="position: fixed;
                            top: 159px;
                            font-family: Almarai;
                            left: 5px;"
      >{{ this.items[0].expiration_date }} / {{ this.items[0].created_at }}</h3>
      <img
        src="@/assets/images/card/back2.jpeg"
        alt=""
        :style="{'height':'100%','width':'100%' ,'position': 'absolute',
                 'top':'100%','bottom':'200%','left':'0','right':'0'}"
      >
      <h6
        v-if="this.items[0].carr_data"
        style="position: fixed;
                    top: 98%;
                    font-family: Almarai;
                    font-size: 9px!important;
                    left: 2px;"
      >{{ this.items[0].carr_data.model }}</h6>
      <h6
        v-if="this.items[0].carr_data"
        style="position: fixed;
                    top: 95%;
                    font-family: Almarai;
                    left: 182px;"
      >{{ this.items[0].carr_data.manufacturer }}</h6>
      <h5
        style="position: fixed;
                    top: 115%;
                    font-family: Almarai;
                    left: 5px;"
      >{{ this.items[0].color }}</h5>
      <h5
        style="position: fixed;
                    top: 115%;
                    font-family: Almarai;
                    left: 192px;"
      >{{ this.items[0].created_year }}</h5>
      <h5
        v-if="this.items[0].carr_data"
        style="position: fixed;
                    top: 134%;
                    font-family: Almarai;
                    left: 15px;"
      >{{ this.items[0].carr_data.wight1 }}</h5>
      <h5
        v-if="this.items[0].carr_data"
        style="position: fixed;
                    top: 134%;
                    font-family: Almarai;
                    left: 192px;"
      >{{ this.items[0].carr_data.wight }}</h5>
      <h4
        style="position: fixed;
                    top: 156%;
                    color: white!important;
                    filter:  brightness(0) invert(1);
                    font-weight: bolder;
                    left: 65px;"
      >{{ this.items[0].chassis_number }}</h4>
      <h6
        v-if="this.items[0].carr_data"
        style="position: fixed;
                    top: 175%;
                    font-family: Almarai;
                    color: white!important;
                    filter:  brightness(0) invert(1);
                    left: 10px;"
      >{{ this.items[0].carr_data.passenger }}</h6>
      <h6
        v-if="this.items[0].carr_data"
        style="position: fixed;
                    top: 175%;
                    font-family: Almarai;
                    color: white!important;
                    filter:  brightness(0) invert(1);
                    left: 135px;"
      >{{ this.items[0].car_type }}</h6>
    </b-card>
  </div>
</template>

<script>

export default {
  data() {
    return {
      deleteElement: false,
      filter: null,
      filter1: null,
      element: null,
      currentPage: 1,
      totalRows: 0,
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: this.$t('id') },
        { key: 'owwner_id', label: this.$t('owwner_id') },
        { key: 'No', label: this.$t('CkeckkNo') },
        { key: 'chassis_number', label: this.$t('chassis_number') },
        { key: 'chassis_number_type', label: this.$t('chassis_number_type') },
        { key: 'owner_type', label: this.$t('owner_type') },
        { key: 'created_year', label: this.$t('created_year') },
        { key: 'first_registeraion', label: this.$t('first_registeraion') },
        { key: 'cabin_number', label: this.$t('cabin_number') },
        { key: 'cabin_number_type', label: this.$t('cabin_number_type') },
        { key: 'cabin_reserve_number', label: this.$t('cabin_reserve_number') },
        { key: 'cabin_reserve_number_type', label: this.$t('cabin_reserve_number_type') },
        { key: 'motor_number', label: this.$t('motor_number') },
        { key: 'motor_number_type', label: this.$t('motor_number_type') },
        { key: 'motor_reserve_number', label: this.$t('motor_reserve_number') },
        { key: 'motor_reserve_number_type', label: this.$t('motor_reserve_number_type') },
        { key: 'motor_manufacturer', label: this.$t('motor_manufacturer') },
        { key: 'motor_model', label: this.$t('motor_model') },
        { key: 'car_type', label: this.$t('car_type') },
        { key: 'fuel_type', label: this.$t('fuel_type') },
        { key: 'color', label: this.$t('color') },
        { key: 'fee', label: this.$t('Fee') },
        { key: 'expiration_date', label: this.$t('expiration_date') },
        { key: 'brake', label: this.$t('brake') },
        { key: 'light', label: this.$t('light') },
        { key: 'combustion', label: this.$t('combustion') },
        { key: 'wheel', label: this.$t('wheel') },
        { key: 'owwner_data', label: this.$t('owwner') },
        { key: 'carr_data', label: this.$t('car') },
        { key: 'platee', label: this.$t('plate') },
        { key: 'notes', label: this.$t('notes') },
        { key: 'created_at_updated_at', label: this.$t('created_at_updated_at') },
      ]
    },
    items() {
      return this.$store.getters['delivery/GetElements']
    },
    pagination() {
      return `page=${this.currentPage}&search=${this.filter}`
    },
  },
  watch: {
    currentPage() {
      this.getElement()
    },
  },
  created() {
    this.chekker = true
    this.getElement()
  },
  methods: {
    async print(itemId) {
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes',
        ],
        styles: [
          '../../../../assets/scss/certificate.css',
        ],
        timeout: 10, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: itemId, // override the window title
        title: itemId,
      }
      // Pass the element id here
      // await this.$htmlToPaper('printMe', { options })
      await this.$htmlToPaper('printMe', { options, styles: ['/style.css'] })
    },
    onEnter() {
      this.chekker = false
      this.currentPage = 1
      this.getElement()
    },
    onEnter1() {
      this.chekker = false
      this.currentPage = 1
      this.getElement1()
    },
    getElement() {
      this.$store.dispatch('delivery/GetElements', `page=${this.currentPage}&search=${this.filter}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
      })
    },
    getElement1() {
      this.$store.dispatch('delivery/GetElementsByRenew', `page=${this.currentPage}&search=${this.filter1}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
      })
    },
    Delete(val) {
      this.deleteElement = true
      this.element = val
    },
    ConfirmDelete() {
      this.$store.dispatch('delivery/Delete', this.element)
    },
    create() {
      this.errors = []
      this.$store.dispatch('delivery/Create', this.items[0]).then(() => {
        this.filter = null
        this.chekker = true
        this.getElement()
        this.clearData()
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
